import request from '@http';

// 人员列表
export function getSysadminUserList(params) {
    return request({
        method: 'GET',
        url: '/api/sysadmin/user/list',
        params,
    })
}

// 添加人员 2022/03/31 王江毅
export function createUser(data) {
    return request({
        method: 'PUT',
        url: '/api/sysadmin/user/create',
        data
    })
}

// 更新人员 2022/03/31 王江毅
export function updateUser(data) {
    return request({
        method: 'PUT',
        url: '/api/sysadmin/user/update',
        data
    })
}

// 修改登录用户密码 2022/03/31 王江毅
export function userChangepassword(data) {
    return request({
        method: 'PUT',
        url: '/api/user/changepassword',
        data
    })
}

// 用户分配角色 2022/06/16 王江毅
export function userRoleUpdate(data) {
    return request({
        method: 'PUT',
        url: '/api/sysadmin/userrole/update',
        data
    })
}

// 2023-11-09 王江毅  删除系统用户
export function userDeleteAPI(params) {
    return request({
        method: 'GET',
        url: '/api/sysadmin/user/delete',
        params
    })
}